// ループスライダー
export function slider() {
  const slider = document.querySelector<HTMLElement>('.adopt-slider');
  const sliderWrap = document.querySelector<HTMLElement>(
    '.adopt-slider .adopt-slider-wrap',
  );
  const sliderList = document.querySelector<HTMLElement>('.adopt-slider-list');
  const sliderListItem = document.querySelector('.adopt-slider-list > li');
  const listItemMargin = 25;
  const slideSpeed = 6700;

  if (slider && sliderWrap && sliderList && sliderListItem) {
    const listItemCount = sliderList.childElementCount;
    const listItemWidth = sliderListItem.clientWidth;
    const listItemHeight = sliderListItem.clientHeight;
    const listWidth =
      listItemWidth * listItemCount + listItemMargin * listItemCount;

    slider.style.height = listItemHeight + 'px';
    sliderWrap.style.width = listWidth * 2 + 'px';
    sliderList.style.width = listWidth + 'px';

    function sliderClone() {
      const sliderListClone = sliderList.cloneNode(true);
      sliderWrap.appendChild(sliderListClone);
    }

    function sliderPosition() {
      sliderWrap.animate(
        { left: '-' + listWidth + 'px' },
        slideSpeed * listItemCount,
      );
      setTimeout(function () {
        sliderPosition();
      }, slideSpeed * listItemCount);
    }

    sliderClone();
    sliderPosition();
  }
}

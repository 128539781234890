<template>
  <section v-if="!loading" class="section-adopt" data-testid="wrapper">
    <div class="container-top">
      <h2 class="adopt-title">
        大学病院をはじめ多くの<br />医療機関で採用されています
      </h2>
    </div>
    <div class="adopt-slider">
      <div class="adopt-slider-wrap">
        <ul class="adopt-slider-list">
          <li
            v-for="(
              medicalInstitutions, medicalInstitutionsIndex
            ) in matrixMedicalInstitutions"
            :key="medicalInstitutionsIndex"
          >
            <ul class="adopt-slider-list-child">
              <li
                v-for="(
                  medicalInstitution, medicalInstitutionIndex
                ) in medicalInstitutions"
                :key="medicalInstitutionIndex"
                class="slider-item"
              >
                <a
                  :href="`/medical_institution/list.html#${medicalInstitution.slug}`"
                >
                  <figure class="slider-item-img">
                    <img
                      :src="medicalInstitution.lpLogoImageUrl"
                      :alt="medicalInstitution.name"
                    />
                  </figure>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref, nextTick } from 'vue';
import { MedicalInstitution, useStore } from '../stores';
import { slider } from '../modules/top';

export default defineComponent({
  setup() {
    const loading = ref(false);
    const store = useStore();

    onBeforeMount(async () => {
      loading.value = true;

      const response = await store.fetchMedicalInstitutions();

      if (response) {
        loading.value = false;
        await nextTick();
        slider();
      }
    });

    const matrixMedicalInstitutions = computed(() => {
      return store.medicalInstitutions.reduce((acc, current, index) => {
        if (index % 2 === 0) acc.push([]);
        acc[Math.floor(index / 2)].push(current);
        return acc;
      }, [] as Array<Array<MedicalInstitution>>);
    });

    return {
      loading,
      matrixMedicalInstitutions,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '../assets/styles/_mixin.scss';
@import '../assets/styles/_variables.scss';

.section-adopt {
  padding: 52px 0 10px;
  z-index: 0;
  position: relative;
  background: $color-primary-5;

  .adopt-title {
    font-size: vwsp(26);
    font-weight: bold;
    line-height: 1.46;
    text-align: center;
    letter-spacing: -0.2px;
    position: relative;
    padding-bottom: 25px;
    margin-bottom: 30px;
    @include mq(xs) {
      font-size: 26px;
    }

    &::after {
      content: '';
      width: 121px;
      height: 11px;
      background: url('../assets/img/icon_headline_tail.svg') repeat-x
        center/cover;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.adopt-slider {
  position: relative;
  overflow: hidden;

  .adopt-slider-wrap {
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
  }

  .adopt-slider-list {
    display: flex;

    > li {
      margin-right: 25px;

      .adopt-slider-list-child {
        .slider-item {
          background: $white;
          box-shadow: 0 0 6px 0 rgba($color-primary-2, 0.4);
          margin-bottom: 25px;

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 210px;
            height: 95px;
            padding: 10px;
            box-sizing: border-box;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>

import { defineStore } from 'pinia';
import { GetTop200Response } from '@medpeer-dev/yakubato-lp-api';

type State = GetTop200Response;
export type MedicalInstitution =
  GetTop200Response['medicalInstitutions'][number];

export const useStore = defineStore('index', {
  state: (): State => ({
    medicalInstitutions: [],
  }),
  actions: {
    async fetchMedicalInstitutions() {
      try {
        const response = await this.$api.topApi.getTop();
        this.medicalInstitutions = response.data.medicalInstitutions;
        return true;
      } catch {
        return false;
      }
    },
  },
});
